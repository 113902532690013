import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  completeInvitationByTokenAction,
  getInvitationByTokenAction,
} from "@store/reducers/profile";
import { useForm } from "react-hook-form";
import Storage from "@utils/storage";
import useNotification from "@hooks/useNotification";
import useLogin from "@hooks/useLogin";
import { useEffect } from "react";

const useMultiLogin = () => {
  const dispatch = useDispatch();
  const { handleSubmit, errors, register } = useForm();
  const notify = useNotification();
  const { handleLoginForm, isLoggedIn } = useLogin();

  const invitation = useSelector((state) => state.profile.invitation);

  const { id } = useParams();

  const completeInvitationByToken = async (data) => {
    try {
      const completeInvitationByTokenResponse = await dispatch(
        completeInvitationByTokenAction({
          urlParams: { token: id },
          data: { password: data.password },
        })
      );
      if (completeInvitationByTokenResponse.error) {
        notify.onlyIfContainsError(completeInvitationByTokenResponse);
      } else {
        await Storage.setToken(completeInvitationByTokenResponse.token);
        await handleLoginForm({
          username: invitation.invitation.email,
          password: data.password,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await dispatch(getInvitationByTokenAction({ token: id }));
    })();
  }, [dispatch, id]);

  return {
    errors,
    register,
    handleSubmit,
    completeInvitationByToken,
    invitation,
    isLoggedIn,
  };
};
export default useMultiLogin;
