import { FormattedNumber } from "react-intl";

const IntlNumberEUR = ({ value, style = "currency" }) => (
  <FormattedNumber
    style={style}
    minimumFractionDigits={2}
    maximumFractionDigits={2}
    currencyDisplay="symbol"
    currency="EUR"
    value={value}
  />
);
export default IntlNumberEUR;
