import React, { useEffect, useRef, useState } from "react";
import { Nav, NavItem } from "reactstrap";
import LocalizedNavLink from "@components/I18n/LocalizedNavLink";
import { FormattedMessage } from "react-intl";

function BottomTabNavigator({ data }) {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const main = document.querySelector("main");

    setHeight(ref.current.clientHeight);

    if (main && height) {
      document.querySelector("main").style.paddingBottom = height + "px";
    }

    const handleResize = () => {
      setHeight(ref.current.clientHeight);

      if (main && height) {
        main.style.paddingBottom = height + "px";
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (main) {
        main.removeAttribute("style");
      }
    };
  }, [height]);

  return (
    <div className={"d-md-none"}>
      <nav className="bottom-tab-navigator" role="navigation" ref={ref}>
        <Nav>
          {data.map(({ path, icon, label }, index) => (
            <NavItem key={`tab-${index}`}>
              <LocalizedNavLink path={path}>
                <div className="content">
                  <div className={"content__icon"}>{icon}</div>
                  <span className={"content__label"}>
                    <FormattedMessage id={label} />
                  </span>
                </div>
              </LocalizedNavLink>
            </NavItem>
          ))}
        </Nav>
      </nav>
    </div>
  );
}

export default BottomTabNavigator;
