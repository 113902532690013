import useTenant from "@hooks/useTenant";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CardText, CustomInput, FormGroup } from "reactstrap";

const AcceptAgreementCheckbox = ({
  agreementMessage,
  agreementUrlText,
  register,
  errors,
  identifier,
  disabled,
}) => {
  const intl = useIntl();
  const { userAgreementUrl } = useTenant();
  return (
    <FormGroup className={"form-inline accept-agreement"}>
      <div className={"form-check mb-2 w-100 justify-content-start"}>
        <CustomInput
          type="checkbox"
          name="agreement"
          id={identifier}
          innerRef={register({
            required: intl.formatMessage({
              id: "validation.required",
            }),
          })}
          disabled={disabled}
          label={
            <CardText className={errors ? "label-required" : null}>
              <FormattedMessage
                values={{
                  link: (
                    <a
                      target={"_blank"}
                      href={userAgreementUrl}
                      rel="noreferrer"
                    >
                      <FormattedMessage id={agreementUrlText} />
                    </a>
                  ),
                }}
                id={agreementMessage}
              />
            </CardText>
          }
        />
      </div>
      {errors.agreement && (
        <div className="text-danger">
          <FormattedMessage
            values={{
              type: <FormattedMessage id={agreementUrlText} />,
            }}
            id={"validation.required.agreement"}
          />
        </div>
      )}
    </FormGroup>
  );
};

export default AcceptAgreementCheckbox;
