import { getIdentificationItemTypes } from "@store/reducers/identificationItemTypes";
import { myCustomerInvoiceData } from "@store/reducers/profile";
import { getSaleItems } from "@store/reducers/saleItemCategories";
import { myPrepaidSaleItems } from "@store/reducers/prepaidSaleItems";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import linkedMobileCategoryPages from "@utils/linkedMobileCategoryPages";
import { applicationRoutes } from "@utils";

const useLinkedCategoryPageRoutes = () => {
  const dispatch = useDispatch();
  const [appRoutes, setAppRoutes] = useState([]);

  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );
  const CATEGORY_CODE_SUBSCRIPTIONS = "SUBSCRIPTION";
  const CATEGORY_CODE_CHARGE = "CHARGE";
  const CATEGORY_CODE_GIFT = "GIFT";
  const CATEGORY_CODE_PREPAID = "PREPAID";

  useEffect(() => {
    (async () => {
      let filteredShopRoutes = linkedMobileCategoryPages.shop;
      let filteredCarRoutes = linkedMobileCategoryPages.car;
      let filteredMyPurchasesRoutes = linkedMobileCategoryPages.myPurchases;

      if (accountStatus === "COMPLETED") {
        await dispatch(
          getSaleItems({ categoryCode: CATEGORY_CODE_CHARGE })
        ).then(({ payload }) => {
          if (!payload.data.length) {
            filteredShopRoutes = filteredShopRoutes.filter(
              (route) => route.path !== `/dashboard/credit`
            );
          } else {
            const creditRoute = filteredShopRoutes.find(
              (route) => route.path === `/dashboard/credit`
            );
            creditRoute.visible = true;
          }
        });

        await dispatch(
          getSaleItems({ categoryCode: CATEGORY_CODE_SUBSCRIPTIONS })
        ).then(({ payload }) => {
          if (!payload.data.length) {
            filteredShopRoutes = filteredShopRoutes.filter(
              (route) => route.path !== `/dashboard/subscriptions`
            );
          } else {
            const subscriptionRoute = filteredShopRoutes.find(
              (route) => route.path === `/dashboard/subscriptions`
            );
            subscriptionRoute.visible = true;
          }
        });

        await dispatch(getSaleItems({ categoryCode: CATEGORY_CODE_GIFT })).then(
          ({ payload }) => {
            if (!payload.data.length) {
              filteredShopRoutes = filteredShopRoutes.filter(
                (route) => route.path !== `/dashboard/gift-voucher`
              );
            } else {
              const giftRoute = filteredShopRoutes.find(
                (route) => route.path === `/dashboard/gift-voucher`
              );
              giftRoute.visible = true;
            }
          }
        );

        Promise.all([
          await dispatch(myPrepaidSaleItems()),
          await dispatch(getSaleItems({ categoryCode: CATEGORY_CODE_PREPAID })),
        ]).then(([myPrepaidSaleItemsRes, prepaidSaleItemsRes]) => {
          if (
            !myPrepaidSaleItemsRes.payload.length &&
            !prepaidSaleItemsRes.payload.data.length
          ) {
            filteredShopRoutes = filteredShopRoutes.filter(
              (route) => route.path !== `/dashboard/webshop`
            );
          } else {
            const webshopRoute = filteredShopRoutes.find(
              (route) => route.path === `/dashboard/webshop`
            );
            webshopRoute.visible = true;
          }
        });

        await dispatch(getIdentificationItemTypes()).then(({ payload }) => {
          filteredCarRoutes = filteredCarRoutes.filter(
            (route) =>
              !route.code ||
              (Array.isArray(route.code) &&
                payload.some((el) => route.code.includes(el.code))) ||
              payload.some((el) => el.code === route.code)
          );
        });

        await dispatch(myCustomerInvoiceData()).then(({ payload }) => {
          if (!payload) {
            filteredMyPurchasesRoutes = filteredMyPurchasesRoutes.filter(
              (route) => route.path !== `/dashboard/invoice`
            );
          } else {
            const inVoiceRoute = filteredMyPurchasesRoutes.find(
              (route) => route.path === `/dashboard/invoice`
            );
            inVoiceRoute.visible = true;
          }
        });
      }

      setAppRoutes(() => {
        return {
          shop: filteredShopRoutes,
          car: filteredCarRoutes,
          myPurchases: filteredMyPurchasesRoutes,
        };
      });

      const shopTab = applicationRoutes.bottomTabNavigator.find(
        (item) => item.path === "/dashboard/choose-shop-category"
      );
      const carTab = applicationRoutes.bottomTabNavigator.find(
        (item) => item.path === "/dashboard/choose-car-category"
      );
      const myPurchasesTab = applicationRoutes.bottomTabNavigator.find(
        (item) => item.path === "/dashboard/choose-my-purchases-category"
      );

      if (shopTab && !filteredShopRoutes.length) {
        shopTab.visible = false;
      } else if (shopTab && filteredShopRoutes.length === 1) {
        shopTab.visible = true;
        shopTab.path = filteredShopRoutes[0].path;
      } else if (shopTab) {
        shopTab.visible = true;
      }

      if (carTab && !filteredCarRoutes.length) {
        carTab.visible = false;
      } else if (carTab && filteredCarRoutes.length === 1) {
        carTab.visible = true;
        carTab.path = filteredCarRoutes[0].path;
      } else if (carTab) {
        carTab.visible = true;
      }

      if (myPurchasesTab && !filteredMyPurchasesRoutes.length) {
        myPurchasesTab.visible = false;
      } else if (myPurchasesTab && filteredMyPurchasesRoutes.length === 1) {
        myPurchasesTab.visible = true;
        myPurchasesTab.path = filteredMyPurchasesRoutes[0].path;
      } else if (myPurchasesTab) {
        myPurchasesTab.visible = true;
      }
    })();
  }, [accountStatus, dispatch]);

  return {
    linkedMobileCategoryPagesRoutes: appRoutes,
  };
};
export default useLinkedCategoryPageRoutes;
