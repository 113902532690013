import { myAccount, myBalance } from "@store/reducers/profile";
import { getPaymentProviderAction } from "@store/reducers/sales";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useRenderDashboard = () => {
  const [sidebarHidden, setSidebarVisibility] = useState(true);
  const [render, setRenderDashboard] = useState(false);
  const dispatch = useDispatch();
  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );

  useEffect(() => {
    const getCustomerData = async () => {
      try {
        if (accountStatus === "COMPLETED") {
          await Promise.all([
            dispatch(myAccount()),
            dispatch(myBalance()),
            dispatch(getPaymentProviderAction()),
          ]);
        } else {
          await Promise.all([dispatch(myAccount())]);
        }
        setRender(true);
      } catch (e) {
        console.error(e);
      }
    };

    getCustomerData();
  }, [accountStatus, dispatch]);

  const setRender = (isRender) => setRenderDashboard(isRender);

  const setToggleSidebar = () => setSidebarVisibility(!sidebarHidden);

  return {
    setToggleSidebar,
    sidebarHidden,
    setRender,
    render,
  };
};

export default useRenderDashboard;
