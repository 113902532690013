import { useEffect, useState } from "react";

const UseShowOnMobileOrRedirectOnDesktop = () => {
  const [isMobile, setIsMobile] = useState(false);

  const check = () => {
    const w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

    return windowWidth > 768; //returns true for widths larger than 568 pixels
  };

  useEffect(() => {
    setIsMobile(check());

    const handleResize = () => {
      setIsMobile(check());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      setIsMobile(false);
    };
  }, []);

  return {
    isMobile,
  };
};

export default UseShowOnMobileOrRedirectOnDesktop;
