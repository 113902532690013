import useNotification from "@hooks/useNotification";
import {
  editMyPrefferedLocation,
  getLocationsAction,
  myPrefferedLocation,
} from "@store/reducers/profile";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const useLocationsDetails = () => {
  const dispatch = useDispatch();
  const notify = useNotification();
  const { register, handleSubmit, errors, setValue } = useForm();

  const { locationList, prefferedLocation, data, isLoading } = useSelector(
    (state) => state.profile.locations
  );

  const handleMyDetailsForm = async (data) => {
    const response = await dispatch(editMyPrefferedLocation(data));

    if (response.error) {
      notify.onlyIfContainsError(response);
    } else {
      notify.success("api.success.modified");
      await dispatch(myPrefferedLocation());
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getLocationsAction());
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch]);

  return {
    register,
    errors,
    handleSubmit,
    setValue,
    locationList,
    prefferedLocation,
    data,
    isLoading,
    handleMyDetailsForm,
  };
};

export default useLocationsDetails;
