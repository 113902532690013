import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { FiMail } from "react-icons/fi";
import useAccountSettings from "@hooks/useAccountSettings";

const AccountNotVerified = () => {
  const { email } = useSelector((state) => state.profile.account.account);
  const { sendActivationMail } = useAccountSettings();

  return (
    <div className={"account-not-verified"}>
      <Row className={"pt-3"}>
        <Col md={"12"}>
          <Card>
            <CardBody>
              <CardHeader className={"mb-3"}>
                <CardTitle>
                  <FormattedMessage id={"main.accountNotVerified.title"} />
                </CardTitle>
              </CardHeader>

              <div className="p-5 border text-center">
                <div className="content">
                  <h6 className={"mb-3"}>
                    <FormattedMessage id={"main.accountNotVerified.subTitle"} />
                  </h6>
                  <FiMail size={"32px"} className={"mb-3"} />
                  <p>
                    <FormattedMessage
                      id={"main.accountNotVerified.paragraphOne"}
                      values={{
                        email: (
                          <>
                            <br />
                            <strong>{email}</strong>
                          </>
                        ),
                      }}
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id={"main.accountNotVerified.paragraphTwo"}
                    />
                  </p>
                  <p className={"mb-0"}>
                    <FormattedMessage
                      id={"main.accountNotVerified.paragraphThree"}
                      values={{
                        sendAgain: (
                          <>
                            <br />
                            <Button
                              size={"md"}
                              color={"link"}
                              onClick={async () => {
                                await sendActivationMail();
                              }}
                            >
                              <FormattedMessage
                                id={"main.accountNotVerified.sendAgainText"}
                              />
                            </Button>
                          </>
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AccountNotVerified;
