import Storage from "@utils/storage";
import axios from "axios";
import qs from "qs";

const CONTENT_TYPE_JSON = "application/json; charset=UTF-8";
const CONTENT_TYPE_X_WWW_FORM_URLENCODED =
  "application/x-www-form-urlencoded; charset=UTF-8";

const loginErrorMessages = [
  "exception.authentication.status.disabled", // Account disabled
  "exception.authentication.badCredentials", // Account not found
];

// Axios Default Configs
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Accept"] = "*/*";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = CONTENT_TYPE_JSON;
axios.defaults.headers.post["Content-Type"] = CONTENT_TYPE_JSON;
axios.defaults.headers.put["Content-Type"] = CONTENT_TYPE_JSON;

// Axios Interceptors
axios.interceptors.request.use(
  async (config) => {
    if (config.url === "/login") {
      config.headers.post["Content-Type"] = CONTENT_TYPE_X_WWW_FORM_URLENCODED;
    } else {
      config.headers.post["Content-Type"] = CONTENT_TYPE_JSON;
      try {
        const token = await Storage.getToken();
        config.headers["X-auth-Token"] = token;
      } catch (e) {}
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const urlBuilder = (url, urlParams) => {
  let updatedUrl = url;
  Object.keys(urlParams).map(
    (param) => (updatedUrl = updatedUrl.replace(`:${param}`, urlParams[param]))
  );
  return updatedUrl;
};

export const apiAction = async ({
  url = "",
  method = "GET",
  data = null,
  urlParams = {},
}) => {
  const dataOrParams = ["GET", "DELETE"].includes(method.toUpperCase())
    ? "params"
    : "data";

  return axios({
    method,
    url: urlBuilder(url, urlParams),
    [dataOrParams]: data,
    transformRequest: [
      (data, headers) =>
        headers.post["Content-Type"] === CONTENT_TYPE_X_WWW_FORM_URLENCODED
          ? qs.stringify(data)
          : JSON.stringify(data),
    ],
  }).catch(async (err) => {
    const { status, data } = err.response;
    let response;

    switch (status) {
      case 400:
        response = {
          message: "Something went wrong",
          messageKey: "exception.http.400",
        };
        break;
      case 401:
        // Check if error is related to login
        if (loginErrorMessages.includes(data.messageKey)) {
          response = data;
        } else {
          await redirectToLogin();
        }
        break;
      case 403:
        await redirectToLogin();
        break;
      case 409:
        response = data;
        break;
      case 500:
        response = {
          message: "Something went wrong",
          messageKey: "exception.http.500",
        };
        break;
      default:
        response = {
          message: "Something went wrong",
          messageKey: "exception.http.unexpectedError",
        };
        break;
    }

    return Promise.reject(response);
  });
};

const redirectToLogin = async () => {
  await Storage.clear();
  window.location.href = "/";
};

export default apiAction;
