import React from "react";
import { Col, Row } from "reactstrap";
import ChooseCategory from "@components/ChooseCategory/ChooseCategory";
import useNavigation from "@hooks/useNavigation";
import actionableMobileCategoryPages from "@utils/actionMobileCategoryPages";
import linkedMobileCategoryPagesRoutes from "@utils/linkedMobileCategoryPages";
import useAccountSettings from "@hooks/useAccountSettings";
import { useSelector } from "react-redux";
import useLocationsDetails from "@hooks/useLocationsDetails";

const ChooseProfileCategory = () => {
  let linkedProfileCategories = linkedMobileCategoryPagesRoutes.profile;
  const logoutData = actionableMobileCategoryPages.logOut;
  const {
    account: { admin },
  } = useAccountSettings();
  const CATEGORY_CODE_CHARGE = "CHARGE";
  const saleItems = useSelector(
    (state) => state.saleItem.list[CATEGORY_CODE_CHARGE]
  );
  const { locationList } = useLocationsDetails();

  if (!admin) {
    linkedProfileCategories = linkedProfileCategories.filter(
      (item) => !item.options.isAdmin
    );
  }

  if (!saleItems?.length) {
    linkedProfileCategories = linkedProfileCategories.filter(
      (item) => !item.options.saleItems
    );
  }

  if (!locationList?.length && locationList?.length > 1) {
    linkedProfileCategories = linkedProfileCategories.filter(
      (item) => !item.options.preferredLocations
    );
  }

  const { logout } = useNavigation();

  return (
    <div className={"mt-3"}>
      {linkedProfileCategories?.map((data, index) => (
        <Row key={index} className={"mx-0"}>
          <Col md={12}>
            <ChooseCategory data={data} />
          </Col>
        </Row>
      ))}
      <Row>
        <Col md={12}>
          <ChooseCategory data={logoutData} action={logout} />
        </Col>
      </Row>
    </div>
  );
};

export default ChooseProfileCategory;
