import { useParams } from "react-router-dom";

const PreviewInvoice = () => {
  const { uuid } = useParams();
  return (
    <iframe
      title={"preview-invoice"}
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      width="100%"
      height="100%"
      style={{ minHeight: "100vh" }}
      scrolling="auto"
      src={`${process.env.REACT_APP_BASE_URL}/invoices/${uuid}`}
    />
  );
};

export default PreviewInvoice;
