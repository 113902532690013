import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { AiOutlineRight } from "react-icons/ai";
import LocalizedLink from "@components/I18n/LocalizedLink";

function ChooseCategory({ data, action }) {
  return (
    <>
      {data.type === "link" && (
        <Card className={"choose-category"}>
          <LocalizedLink path={data.path} className={"choose-category__link"}>
            <CardBody>
              <div className={"choose-category-container"}>
                <div className={"choose-category-content"}>
                  <span className={"choose-category-content__icon"}>
                    {data.icon}
                  </span>
                  <h5 className={"choose-category-content__title"}>
                    <FormattedMessage id={data.title} />
                  </h5>
                </div>
                <AiOutlineRight />
              </div>
            </CardBody>
          </LocalizedLink>
        </Card>
      )}
      {data.type === "action" && (
        <Card className={"choose-category"}>
          <Button
            color={"link"}
            onClick={(e) => {
              e.preventDefault();
              action();
            }}
            className={"choose-category__link"}
          >
            <CardBody>
              <div className={"choose-category-container"}>
                <div className={"choose-category-content"}>
                  <span className={"choose-category-content__icon"}>
                    {data.icon}
                  </span>
                  <h5 className={"choose-category-content__title"}>
                    <FormattedMessage id={data.title} />
                  </h5>
                </div>
                <AiOutlineRight />
              </div>
            </CardBody>
          </Button>
        </Card>
      )}
    </>
  );
}

export default ChooseCategory;
