import { useDispatch, useSelector } from "react-redux";
import { getSystemImagesAction } from "@store/reducers/systemImages";
import { useEffect } from "react";

const useSystemImages = () => {
  const dispatch = useDispatch();
  const { systemImages, loading } = useSelector((state) => state?.systemImages);
  const code = "AUTH_COVER";

  useEffect(() => {
    (async () => {
      await dispatch(getSystemImagesAction({ code: code }));
    })();
  }, [dispatch]);

  return {
    systemImages,
    loading,
  };
};

export default useSystemImages;
