import React, { Suspense, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Spinner } from "@components/Spinner";
import NotFound from "./NotFound";
import { LocalizedSwitch } from "@components/I18n/LocalizedSwitch";
import { useSelector } from "react-redux";
import AccountNotVerified from "@components/AccountNotVerified/AccountNotVerified";
import useShowOnlyOnMobileOrRedirectOnDesktop from "@hooks/useShowOnlyOnMobileOrRedirectOnDesktop";
import { useIntl } from "react-intl";

const DashboardRoutes = ({ applicationRoutes }) => {
  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );

  const { isMobile } = useShowOnlyOnMobileOrRedirectOnDesktop();
  const history = useHistory();
  const location = useLocation();
  const { locale } = useIntl();

  if (accountStatus === "NEW") {
    applicationRoutes.dashboard.map((route) => {
      route.component = AccountNotVerified;
      return route;
    });
  }

  useEffect(() => {
    if (isMobile) {
      const filteredRoutes = applicationRoutes.dashboard.filter(
        ({ isCategoryPage }) => isCategoryPage === true
      );
      filteredRoutes.forEach(({ path }) => {
        if (location.pathname === `/${locale}${path}`) {
          history.push("/dashboard");
        }
      });
    }
  }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Suspense fallback={<Spinner />}>
      <LocalizedSwitch>
        {applicationRoutes.dashboard.map((route) => (
          <Route
            {...route}
            path={
              route.param ? `${route.path}/${route.param}` : `${route.path}`
            }
            key={route.path}
          />
        ))}
        <Route component={NotFound} />
      </LocalizedSwitch>
    </Suspense>
  );
};

export default DashboardRoutes;
