import { withRouter } from "react-router-dom";
import { Navbar } from "reactstrap";
import useTenant from "@hooks/useTenant";
import LocalizedLink from "@components/I18n/LocalizedLink";
import AnonymousWelcome from "@components/AnonymousWelcome";
import BottomTabNavigator from "@components/BottomTabNavigator/BottomTabNavigator";
import { applicationRoutes } from "@utils";

const AnonymousNavigation = (props) => {
  const { logo } = useTenant();

  return (
    <>
      <Navbar expand="md">
        <div
          className={
            "d-flex d-md-none justify-content-between align-items-center w-100 py-2 py-md-0"
          }
        >
          <LocalizedLink
            path="/guest/webshop"
            className="logo d-block d-md-none border-0"
          >
            <img src={logo} alt="logo" />
          </LocalizedLink>
        </div>
        <div className={"d-none d-md-flex"}>
          <AnonymousWelcome />
        </div>
      </Navbar>
      <BottomTabNavigator
        data={applicationRoutes.anonymousBottomTabNavigator}
      />
    </>
  );
};
export default withRouter(AnonymousNavigation);
