import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import useConfirmEmailChange from "@hooks/useConfirmEmailChange";
import LocalizedLink from "@components/I18n/LocalizedLink";
import CompactHeader from "@components/CompactHeader/CompactHeader";
import Background from "@components/Background/AuthBg";
import useSystemImages from "@hooks/useSystemImages";

const ConfirmEmailChange = () => {
  const { isLoading, validToken, activated } = useConfirmEmailChange();

  const { systemImages } = useSystemImages();

  return (
    <Row>
      <Col lg={"6"} className={"px-0"}>
        <div className={"auth-left"}>
          <Card className={"auth-form-wrapper"}>
            <CompactHeader />

            <div className="content">
              <CardBody>
                <div className={"header-wrapper"}>
                  <CardTitle className={"p-3 mb-0"}>
                    <FormattedMessage id="auth.changeemailaddress.title" />
                  </CardTitle>
                  {!isLoading ? (
                    validToken && activated ? (
                      <p>
                        <FormattedMessage
                          id={"auth.changeemailaddress.introductionline1"}
                        />
                      </p>
                    ) : (
                      <p className={"error"}>
                        <FormattedMessage
                          id={"auth.changeemailaddress.errorline1"}
                        />
                      </p>
                    )
                  ) : null}
                </div>

                <LocalizedLink
                  className={"btn btn-primary"}
                  path={"/auth/login"}
                >
                  <FormattedMessage id="auth.changeemailaddress.signin" />
                </LocalizedLink>
              </CardBody>
            </div>
          </Card>
        </div>
      </Col>
      <Col lg={"6"} className={"px-0"}>
        <div className={"auth-right"}>
          <Background bg={systemImages} />
        </div>
      </Col>
    </Row>
  );
};
export default ConfirmEmailChange;
