import { actions as AuthActions } from "@store/reducers/auth";
import { getColors } from "@store/reducers/theme";
import Storage from "@utils/storage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toCSS } from "cssjson";
import { unwrapResult } from "@reduxjs/toolkit";

const useApp = () => {
  const [bootApp, setBootApp] = useState(false);
  const projectStyling = useSelector((state) => toCSS(state.theme.styling));
  const dispatch = useDispatch();

  useEffect(() => {
    const bootProject = async () => {
      try {
        // Validate Token
        const token = await Storage.getToken();
        await dispatch(AuthActions.setToken(token));
      } catch (e) {
        await dispatch(AuthActions.setToken(null));
      }
      unwrapResult(await dispatch(getColors()));
      setBoot(true);
    };

    bootProject();
  }, [dispatch]);

  const setBoot = (canBoot) => setBootApp(canBoot);

  return {
    setBoot,
    bootApp,
    projectStyling,
  };
};

export default useApp;
