import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { removeLastWordFromURL } from "@utils/helpers";

const Title = ({ location, applicationRoutes }) => {
  const routes = [
    ...applicationRoutes.dashboard,
    ...applicationRoutes.anonymousDashboard,
  ];
  const { locale } = useIntl();

  const currentTitle = routes.find((r) =>
    !r.param
      ? `/${locale}${r.path}` === location.pathname
      : `/${locale}${r.path}` === removeLastWordFromURL(location.pathname)
  );

  if (!currentTitle || !currentTitle.sidebarName) return null;

  return (
    <div className="title">
      <h3 className="title__header">
        <FormattedMessage id={currentTitle.sidebarName} />
      </h3>
    </div>
  );
};

export default withRouter(Title);
