import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getTenantInformationAction } from "@store/reducers/tenant";

const useTenant = () => {
  const dispatch = useDispatch();

  const { logo, companyName, userAgreementUrl, isLoading } = useSelector(
    (state) => state.tenant
  );

  useEffect(() => {
    (async () => {
      unwrapResult(await dispatch(getTenantInformationAction()));
    })();
  }, [dispatch]);

  return {
    logo,
    companyName,
    userAgreementUrl,
    logoLoading: isLoading,
  };
};

export default useTenant;
