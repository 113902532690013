import PreviewInvoice from "@components/Invoice/PreviewInvoice";
import PrivateRoute from "@components/Router/PrivateRoute";
import PreviewPrepaidSaleItem from "@components/Webshop/PreviewPrepaidSaleItem";
import useApp from "@hooks/useApp";
import AuthLayout from "@layouts/Auth";
import CompleteAccount from "@layouts/CompleteAccount";
import DashboardLayout from "@layouts/Dashboard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route } from "react-router-dom";
import PreviewDiscountVoucher from "@components/DiscountVoucher/PreviewDiscountVoucher";
import { Toastr } from "@components/Toastr";
import { LocalizedSwitch } from "@components/I18n/LocalizedSwitch";
import React from "react";
import ScrollToTop from "@components/ScrollToTop";
import AnonymousDashboardLayout from "@layouts/AnonymousDashboard";

const App = () => {
  const { bootApp, projectStyling } = useApp();

  if (!bootApp) return null;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <style>{`${projectStyling}`}</style>
        </Helmet>
        <Toastr />
      </HelmetProvider>

      <ScrollToTop>
        <LocalizedSwitch>
          <Route path="/invoice-download/:uuid" component={PreviewInvoice} />
          {process.env.REACT_APP_GUEST_WEBSHOP === "true" && (
            <Route path="/guest" component={AnonymousDashboardLayout} />
          )}
          <Route
            path="/voucher-download/:uuid"
            component={PreviewDiscountVoucher}
          />
          <Route
            path="/webshop-download/:uuid"
            component={PreviewPrepaidSaleItem}
          />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/activate" component={AuthLayout} />
          <Route path="/password" component={AuthLayout} />
          <Route path="/username" component={AuthLayout} />
          <Route path={"/invite"} component={AuthLayout} />
          <Route
            path={"/discount-vouchers/:discountVoucherUuid/fulfill"}
            component={AuthLayout}
          />

          <PrivateRoute
            path="/complete-personal-details"
            component={CompleteAccount}
          />
          <PrivateRoute path="/" component={DashboardLayout} />
        </LocalizedSwitch>
      </ScrollToTop>
    </>
  );
};

export default App;
