import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import LocalizedLink from "@components/I18n/LocalizedLink";
import useSystemImages from "@hooks/useSystemImages";
import React from "react";
import CompactHeader from "@components/CompactHeader/CompactHeader";
import Background from "@components/Background/AuthBg";
import { Redirect } from "react-router-dom";
import useInvitation from "@hooks/useInvitation";
import PasswordInput from "@components/PasswordInput/PasswordInput";
import { Spinner } from "@components/Spinner";

const Invite = () => {
  const { systemImages } = useSystemImages();
  const {
    invitation: { isLoading, invitation },
    completeInvitationByToken,
    register,
    errors,
    handleSubmit,
    isLoggedIn,
  } = useInvitation();
  const { formatMessage, locale } = useIntl();

  if (isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: `/${locale}/dashboard`,
          state: {
            breadCrumbs: [{ label: "dashboard", path: `/${locale}/dashboard` }],
          },
        }}
      />
    );
  }

  if (isLoading) return <Spinner />;

  return (
    <>
      {Object.keys(invitation).length !== 0 ? (
        <>
          <Row>
            <Col lg={"6"} className={"px-0"}>
              <div className={"auth-left"}>
                <Card className={"auth-form-wrapper"}>
                  <CompactHeader />

                  <div className="content">
                    <CardBody>
                      <div className={"header-wrapper"}>
                        <CardTitle className="p-3 mb-0">
                          <FormattedMessage id="auth.invite.title" />
                        </CardTitle>
                        <CardSubtitle tag={"p"}>
                          <FormattedMessage id="auth.invite.paragraph" />
                        </CardSubtitle>
                      </div>

                      <Form onSubmit={handleSubmit(completeInvitationByToken)}>
                        <FormGroup>
                          <Label for={"username"} className={"label-required"}>
                            {formatMessage({
                              id: "auth.login.username",
                            })}
                          </Label>
                          <Input
                            defaultValue={invitation.email}
                            disabled={true}
                            type="text"
                            name="username"
                            id="username"
                            placeholder={formatMessage({
                              id: "auth.login.username",
                            })}
                            innerRef={register}
                          />
                        </FormGroup>
                        <FormGroup>
                          <PasswordInput
                            errors={errors}
                            register={register({
                              required: formatMessage({
                                id: "validation.required",
                              }),
                              validate: {
                                length: (value) =>
                                  (value && value.length >= 7) ||
                                  formatMessage({
                                    id: "validation.password.sevenLettersLong",
                                  }),
                                digits: (value) =>
                                  (value && /\d/.test(value)) ||
                                  formatMessage({
                                    id: "validation.password.mustContainDigit",
                                  }),
                                letters: (value) =>
                                  (value && /[a-zA-Z]/.test(value)) ||
                                  formatMessage({
                                    id: "validation.password.mustContainLetter",
                                  }),
                                nospaces: (value) =>
                                  (value && !/\s/.test(value)) ||
                                  formatMessage({
                                    id: "validation.password.mustNotContainSpace",
                                  }),
                              },
                            })}
                            placeholder={formatMessage({
                              id: "auth.login.password",
                            })}
                          />
                        </FormGroup>
                        <Button color="primary" disabled={isLoading}>
                          <FormattedMessage id="auth.invite.cta" />
                        </Button>
                      </Form>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </Col>
            <Col lg={"6"} className={"px-0"}>
              <div className={"auth-right"}>
                <Background bg={systemImages} />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col lg={"6"} className={"px-0"}>
              <div className={"auth-left"}>
                <Card className={"auth-form-wrapper"}>
                  <CompactHeader />

                  <div className="content">
                    <CardBody>
                      <div className="header-wrapper">
                        <CardTitle className="p-3 mb-0">
                          <FormattedMessage id="auth.invite.title" />
                        </CardTitle>
                        <p className={"error"}>
                          <FormattedMessage id={"auth.invite.errorline1"} />
                        </p>
                      </div>
                      <LocalizedLink
                        className={"btn btn-primary"}
                        path={"/auth/login"}
                      >
                        <FormattedMessage id="auth.activation.signin" />
                      </LocalizedLink>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </Col>
            <Col lg={"6"} className={"px-0"}>
              <div className={"auth-right"}>
                <Background bg={systemImages} />
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Invite;
