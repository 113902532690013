import {
  TbAt,
  TbBellCog,
  TbCoinEuro,
  TbDroplets,
  TbFileText,
  TbGift,
  TbLanguage,
  TbLockOpen,
  TbMapPin,
  TbNumber,
  TbPassword,
  TbShoppingBag,
  TbStack2,
  TbTransactionEuro,
  TbUser,
} from "react-icons/tb";

const linkedMobileCategoryPages = {
  shop: [
    {
      icon: <TbCoinEuro />,
      path: "/dashboard/credit",
      title: "main.mobileCategory.credit",
      visible: false,
      type: "link",
    },
    {
      icon: <TbShoppingBag />,
      path: "/dashboard/webshop",
      title: "main.mobileCategory.prepaidSaleItems",
      visible: false,
      type: "link",
    },
    {
      icon: <TbStack2 />,
      path: "/dashboard/subscriptions",
      title: "main.mobileCategory.subscriptions",
      visible: false,
      type: "link",
    },
    {
      icon: <TbGift />,
      path: "/dashboard/gift-voucher",
      title: "main.mobileCategory.gift",
      visible: false,
      type: "link",
    },
  ],
  car: [
    {
      icon: <TbNumber />,
      path: "/dashboard/license-plate",
      title: "main.mobileCategory.licensePlate",
      visible: false,
      code: "ANPR",
      type: "link",
    },
    {
      icon: <TbDroplets />,
      path: "/dashboard/clean-card",
      title: "main.mobileCategory.cleanCard",
      visible: false,
      code: ["RFID", "BARCODE"],
      type: "link",
    },
  ],
  myPurchases: [
    {
      icon: <TbTransactionEuro />,
      path: "/dashboard/transactions",
      title: "main.mobileCategory.transactions",
      visible: true,
      type: "link",
    },
    {
      icon: <TbFileText />,
      path: "/dashboard/invoice",
      title: "main.mobileCategory.invoice",
      visible: false,
      type: "link",
    },
  ],
  profile: [
    {
      icon: <TbUser />,
      path: "/my-details/personalInformation",
      title: "main.mobileCategory.personalInformation",
      visible: true,
      options: {},
      type: "link",
    },
    {
      icon: <TbFileText />,
      path: "/my-details/invoiceDetails",
      title: "main.mobileCategory.invoiceDetails",
      visible: true,
      options: {},
      type: "link",
    },
    {
      icon: <TbBellCog />,
      path: "/my-details/settingDetails",
      title: "main.mobileCategory.settingDetails",
      visible: true,
      saleItems: true,
      options: {
        isAdmin: true,
        saleItems: true,
      },
      type: "link",
    },
    {
      icon: <TbLanguage />,
      path: "/my-details/preferredLanguage",
      title: "main.mobileCategory.preferredLanguage",
      visible: true,
      options: {
        isAdmin: true,
      },
      type: "link",
    },
    {
      icon: <TbMapPin />,
      path: "/my-details/locationsDetails",
      title: "main.mobileCategory.locationsDetails",
      visible: true,
      options: {
        preferredLocations: true,
      },
      type: "link",
    },
    {
      icon: <TbLockOpen />,
      path: "/my-details/accessToAccount",
      title: "main.mobileCategory.accessToAccount",
      visible: true,
      options: {},
      type: "link",
    },
    {
      icon: <TbAt />,
      path: "/my-details/usernameDetails",
      title: "main.mobileCategory.usernameDetails",
      visible: true,
      options: {
        isAdmin: true,
      },
      type: "link",
    },
    {
      icon: <TbPassword />,
      path: "/my-details/passwordDetails",
      title: "main.mobileCategory.passwordDetails",
      visible: true,
      options: {},
      type: "link",
    },
  ],
};

export default linkedMobileCategoryPages;
