import { TbLogout } from "react-icons/tb";

const actionableMobileCategoryPages = {
  logOut: {
    icon: <TbLogout />,
    title: "main.mobileCategory.logOut",
    type: "action",
  },
};

export default actionableMobileCategoryPages;
