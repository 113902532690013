import useNavigation from "@hooks/useNavigation";
import useSidebar from "@hooks/useSidebar";
import { AiOutlineLogout, AiOutlineUser } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { Nav } from "reactstrap";
import useTenant from "../../hooks/useTenant";
import LocalizedLink from "@components/I18n/LocalizedLink";
import LocalizedNavLink from "@components/I18n/LocalizedNavLink";

const Sidebar = (props) => {
  const { logo } = useTenant();
  const { scrollTop } = useSidebar(props);
  const { logout } = useNavigation(props);
  const { applicationRoutes } = props;

  const mapLinks = applicationRoutes.dashboard
    .filter((route) => route.visibleOnSidebar)
    .map((prop) => {
      return (
        <li onClick={scrollTop} key={prop.path} className="first-level">
          <LocalizedNavLink
            path={prop.path}
            state={{ breadCrumbs: prop.path }}
            className="sidebar-link"
            activeClassName="active"
          >
            {prop.sidebarIcon}
            <span className="hide-menu">
              <FormattedMessage id={prop.sidebarName} />
            </span>
          </LocalizedNavLink>
        </li>
      );
    });

  return (
    <aside
      className={"left-sidebar d-none d-md-flex"}
      id="sidebarbg"
      data-sidebarbg="skin6"
    >
      <div className="left-sidebar-inner">
        <LocalizedLink path="/dashboard" className="logo">
          <img src={logo} alt="logo" />
        </LocalizedLink>

        <div className="scroll-sidebar">
          <div className="scrollbar-container sidebar-nav ps--active-y">
            <Nav id="sidebarnav" className="selected sidebar-item">
              {mapLinks}
            </Nav>
            <div onClick={scrollTop} className="nav-additional">
              <AiOutlineUser />
              <LocalizedNavLink path={"/my-details/personalInformation"}>
                <FormattedMessage id="main.sidebar.myDetails" />
              </LocalizedNavLink>
            </div>
            <div onClick={scrollTop} className="nav-additional">
              <div onClick={logout} className={"d-flex align-items-center"}>
                <AiOutlineLogout />
                <span>
                  <FormattedMessage id="main.navigation.logOut" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};
export default withRouter(Sidebar);
