import { applicationRoutes } from "@utils";

const useSidebar = (props) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.toggleSidebar();
  };

  applicationRoutes.dashboard.find((r) => r.path);
  return {
    scrollTop,
    applicationRoutes,
  };
};
export default useSidebar;
