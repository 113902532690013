import React from "react";
import { Col, Row } from "reactstrap";
import ChooseCategory from "@components/ChooseCategory/ChooseCategory";
import useLinkedCategoryPageRoutes from "@hooks/useLinkedCategoryPageApplicationRoutes";
import { Spinner } from "@components/Spinner";

const ChooseCarCategory = () => {
  const { linkedMobileCategoryPagesRoutes } = useLinkedCategoryPageRoutes();
  const carCategories = linkedMobileCategoryPagesRoutes.car;

  if (!carCategories) return <Spinner />;

  return (
    <div className={"mt-3"}>
      {carCategories.map((data, index) => (
        <Row key={index} className={"mx-0"}>
          <Col md={12}>
            <ChooseCategory data={data} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default ChooseCarCategory;
